import { initializeApp,getApps } from "firebase/app";
import { getFirestore,  } from "firebase/firestore";

import {
	getAuth,
	setPersistence,
	browserLocalPersistence,
} from "firebase/auth";

export default function useFireClient() {
	const config = useRuntimeConfig();
	let isDev = config.public.isdev == "yes" ? true : false;
	//console.log("isDev", isDev, "config", config);
	// isDev = false
	let firebaseServerConfig = {}
	if(isDev){
		firebaseServerConfig = {
		apiKey: "AIzaSyBAzVRov73G5yvgZau57eF7-WQb7WGeGIg",
		authDomain: "dbbsclient.firebaseapp.com",
		projectId: "dbbsclient",
		storageBucket: "dbbsclient.appspot.com",
		messagingSenderId: "210890711548",
		appId: "1:210890711548:web:58a413bda3b4b357669279",
	};
	}else{
		firebaseServerConfig = {
			apiKey: "AIzaSyBp24tcMPApybSZbmFZU53wU3EV8Omh-pE",
			authDomain: "ledgerproe-7da75.firebaseapp.com",
			projectId: "ledgerproe-7da75",
			storageBucket: "ledgerproe-7da75.appspot.com",
			messagingSenderId: "757874390129",
			appId: "1:757874390129:web:c8fd882827f0cb7f3857d0",
			measurementId: "G-4137XRQVDW"
		}
	
	}
	let app: any;
	if (getApps().length === 0) {
		app = initializeApp(firebaseServerConfig);
	}
	app = getApps()[0];
	const auth = getAuth(app);
	setPersistence(auth, browserLocalPersistence);
	// firestore().settings({ignoreUndefinedProperties: true});
	//let token = ref<IdTokenResult | null>(null);

	// const roles  = ref<string[]>([]);

	//const usr = userState();

	 

 
	// const setupRoles = (claims: Record<string, any>) => {
	// 	if (claims === null) throw new Error("No claims available");
	// 	let roles = ref<string[]>([]);

	// 	if (claims) {
	// 		if (claims.isClientHq) roles.value.push("isClientHq");
	// 		if (claims.isClientSupervisor) roles.value.push("ClientSupervisor");
	// 		if (claims.isDbAdmin) roles.value.push("isDbAdmin");
	// 		if (claims.isDbbs) roles.value.push("isDbbs");
	// 		if (claims.isDbo) roles.value.push("isDbo");
	// 		if (claims.isDbs) roles.value.push("isDbs");
	// 		if (claims.isGuest) roles.value.push("isGuest");
	// 		if (claims.isUploader) roles.value.push("isUploader");
	// 		//user.setUser( {...user.value, roles: roles.value })
	// 	}
	// 	console.log("roles", roles.value);
	// 	return roles.value;
	// };
	

	return {
		app,
		db: getFirestore(app),
		auth,
	};
}
